const CallbackKuyd = () => {
  const save = (token: string) => {
    sessionStorage.setItem("callback-kuyd", token);
  };

  const get = () => {
    return sessionStorage.getItem("callback-kuyd");
  };

  return {
    save,
    get,
  };
};

export default CallbackKuyd;

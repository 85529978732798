const AccountInfo = () => {
  const save = (email: string, name: string, id: string, token: string) => {
    sessionStorage.setItem(
      "account",
      JSON.stringify({
        email: email,
        name: name,
        id: id,
        token,
      })
    );
  };

  const get = () => {
    return JSON.parse(sessionStorage.getItem("account") || "{}");
  };

  return {
    save,
    get,
  };
};

export default AccountInfo;

import { use, useEffect, useState } from "react";
import AccountInfo from "../lib/account";

export const useSession = () => {
  const [user, setUser] = useState({
    userId: "123",
    name: "John Doe",
    email: "eam@gma.com",
  });
  useEffect(() => {
    setUser(AccountInfo().get());
  }, []);

  return {
    status: "authenticated",
    data: {
      user: user,
    },
  };
};

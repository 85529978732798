const CallInfo = () => {
  const save = (token: string) => {
    sessionStorage.setItem("call", token);
  };

  const get = () => {
    return sessionStorage.getItem("call");
  };

  return {
    save,
    get,
  };
};

export default CallInfo;
